import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private readonly layoutService: VexLayoutService) {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Home',
            route: '/',
            icon: 'mat:insights',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Apps',
        children: [
          {
            type: 'link',
            label: 'Resumen diario',
            route: '/resume',
            icon: 'mat:date_range'
          },
          {
            type: 'link',
            label: 'Reportes',
            route: '/apps/aio-table',
            icon: 'mat:assignment'
          },
          {
            type: 'link',
            label: 'Risk Editor',
            route: '/apps/editor',
            icon: 'mat:chrome_reader_mode'
          },
          {
            type: 'link',
            label: 'Activity control',
            route: '/apps/scrumboard',
            icon: 'mat:assessment',
            badge: {
              value: 'NEW',
              bgClass: 'bg-green-600',
              textClass: 'text-on-primary-600'
            }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Admin',
        children: [
          {
            type: 'link',
            label: 'New Service',
            icon: 'mat:fiber_new',
            route: '/admin/service',
            badge: {
              value: 'NEW',
              bgClass: 'bg-primary-600',
              textClass: 'text-on-primary-600'
            }
          },
          {
            type: 'link',
            label: 'Authentication',
            icon: 'mat:lock',
            route: '/admin/auth',
          },
          {
            type: 'link',
            label: 'Companies',
            icon: 'mat:person_pin',
            route: '/admin/company'
          },
          {
            type: 'link',
            label: 'Clients',
            icon: 'mat:person_add',
            route: '/admin/client'
          },
          {
            type: 'link',
            label: 'Contracts',
            icon: 'mat:playlist_add_check',
            route: '/admin/contract'
          },
          {
            type: 'link',
            label: 'Devices',
            icon: 'mat:contacts',
            route: '/admin/device',
          },
          {
            type: 'link',
            label: 'Emails calendar',
            icon: 'mat:email',
            route: '/admin/email',
          },
        ]
      },

      {
        type: 'link',
        label: 'Configuration',
        route: () => this.layoutService.openConfigpanel(),
        icon: 'mat:settings'
      }
    ]);
  }
}
