import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { enviroment } from '../../../assets/enviroments/enviroment';

interface AuthResponse {
  idToken: string;
  email: string;
  refreshToken: string;
  expiresIn: string;
  localId: string;
  registered?: boolean;
}

export class User {
  constructor(
    public id: string,
    public email: string,
    public emailVerified: boolean,
    public signInProvider?: string
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly API_URL =
    'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key='+enviroment.KEY_PROJECT_WEB;
  private token: string | null = null;
  private authStatus = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  login(email: string, password: string): Observable<User> {
    return this.http
      .post<AuthResponse>(this.API_URL, {
        email,
        password,
        returnSecureToken: true,
      })
      .pipe(
        tap((response) => this.handleAuthentication(response)),
        switchMap((response) => this.getInfoUserContract(response.localId)),
        tap((user) => {
           console.log(user);
           // @ts-ignore
          if(user.clientId != null || user.contractId != null || user.role != null) {
            this.authStatus.next(true);
            // @ts-ignore
            localStorage.setItem('clientId', user.clientId);
            // @ts-ignore
            localStorage.setItem('contractId', user.contractId);
            // @ts-ignore
            localStorage.setItem('role', user.role);
            this.authStatus.next(true);
          }else{
            this.authStatus.next(false);
            catchError(this.handleError)
          }
        }),
        catchError(this.handleError)
      );
  }

  private handleAuthentication(response: AuthResponse): void {
    this.token = response.idToken;
    const expiresIn = +response.expiresIn * 1000;
    const expirationDate = new Date(new Date().getTime() + expiresIn);

    localStorage.setItem('idToken', this.token);
    localStorage.setItem('tokenExpiration', expirationDate.toISOString());

    this.authStatus.next(true);
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Ocurrió un error inesperado.';
    if (error.error && error.error.error) {
      switch (error.error.error.message) {
        case 'EMAIL_NOT_FOUND':
          errorMessage = 'El correo no está registrado.';
          break;
        case 'INVALID_PASSWORD':
          errorMessage = 'La contraseña es incorrecta.';
          break;
        case 'USER_DISABLED':
          errorMessage = 'La cuenta ha sido deshabilitada.';
          break;
      }
    }
    return throwError(() => new Error(errorMessage));
  }

  logout(): void {
    this.token = null;
    localStorage.removeItem('idToken');
    localStorage.removeItem('tokenExpiration');
    this.authStatus.next(false);
  }

  isAuthenticated(): boolean {
    if (!this.token) {
      const storedToken = localStorage.getItem('idToken');
      const storedExpiration = localStorage.getItem('tokenExpiration');

      if (storedToken && storedExpiration) {
        const expirationDate = new Date(storedExpiration);
        if (expirationDate > new Date()) {
          this.token = storedToken;
          this.authStatus.next(true);
          return true;
        }
      }

      return false;
    }

    return true;
  }

  getAuthStatus(): Observable<boolean> {
    return this.authStatus.asObservable();
  }

  getToken(): string | null {
    return localStorage.getItem('idToken');
  }

  getClientId(): string | null {
    return localStorage.getItem('clientId');
  }

  getContractId(): string | null {
    return localStorage.getItem('contractId');
  }

  getRole(): string | null {
    return localStorage.getItem('role');
  }

  getUser(): User | null {
    const token = this.getToken();
    if (!token) {
      return null;
    }

    try {
      const payload = this.decodeToken(token);
      return new User(
        payload.sub || payload.user_id,
        payload.email,
        payload.email_verified,
        payload.firebase?.sign_in_provider
      );
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  private decodeToken(token: string): any {
    try {
      const base64Payload = token.split('.')[1];
      const payload = atob(base64Payload);
      return JSON.parse(payload);
    } catch (error) {
      throw new Error('Invalid token format');
    }
  }

  private getInfoUserContract = (UUID: string) => {
    const path = enviroment.API_BASE_ENDPOINT + '/api/sync/get/user/' + UUID
    return this.http.get<User>(path);
  };
}
